import styled from "styled-components";

export const Container = styled.section`
    
    .headerTabela{
    
        padding: 36px 64px 0px 64px;

        .nomePagina{
            font-size: 2rem;
        }
    }

    main{
        padding: 24px 64px;
        margin: 0 auto;

        .container{
            padding: 42px 54px;
            background-color: var(--white);
            border-radius: 30px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            .nomeSessao{
                font-size: 0.875rem;
                margin-bottom: 48px;
            }           
        }

        @media only screen and (max-width: 900px) {
            padding: 0;
            
            .container{
                padding: 20px;
                margin-top: 36px;

                .nomeSessao{
                    margin-bottom: 8px;
                }
            }
        }
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 22px;

        .inputsContainer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 13px;
        }
   
        .inputWrapper{
            display: flex;
            flex-direction: column;
            .inputLabel{
                font-size: 400;
                font-size: 0.875rem;
                color: var(--input-label);
                text-transform: uppercase;
                margin-left: 13px;
                margin-bottom: 12px;
            }
            .inputStyle{
                border: none;
                font-weight: bold;
                height: 49px;
                border-radius: 40px;
                background-color: var(--background-dashboard);
                
            }
        }

        .w30{
            width: 30%;
        }

        .w20{
            width: 23%;
        }

        .w33{
            width: 33%;
        }
        
        .w50{
            width: 50%;
        }

        @media only screen and (max-width: 900px) {
            gap: 0px;
            margin: 0px;
            padding: 0px;

            label{
                margin-top: 10px;
                
            }

            .inputsContainer{
                display: block;
                .inputWrapper{
                    .inputLabel{
                        margin-bottom: 4px;
                    }
                }
            }

            .w30, .w20, .w33, .w50{
                width: 100%;
            }

            .btnContainer{
                align-self: center;
                margin-top: 20px;
            }
        }
    }

`