import axios from "axios";
import {
    clienteData,
    newBlogPostProps,
    postAgendarPagamentoTransacoesProps,
    postCriarContaVendedorProps,
    postCriarPreCadastroVendedorProps,
    putEditarContaVendedorProps
} from "./models";

export const getCep = (cep: string) => {
    const url = `https://viacep.com.br/ws/${cep}/json/`;

    return axios({
        method: 'get',
        url,
    });
};

export const getDownloadQrCodeImage = async (link: string) => {
    const url = `https://api.qrserver.com/v1/create-qr-code/?data=${link}`;

    try {
        const res = await axios({
            method: 'get',
            url,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/png'
            }
        });
        if(res){
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'qrcode.png'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    } catch (err) {
        console.log(err);
    }

}

export const getJeittoLeadTracking = (
    cpf: string,
    // tel: string,
    leadUrl: string
) => {
    // const url = `https://us-central1-jeitto-backend.cloudfunctions.net/preapproved_insert?cpf=${cpf}&phone=${tel}&url=${leadUrl}`;
    const url = `https://us-central1-jeitto-backend.cloudfunctions.net/preapproved_insert?cpf=${cpf}&url=${leadUrl}`;

    return axios({
        method: 'get',
        url,
    });
};

export const getBancos = (token: string) => {
    const url = `${process.env.REACT_APP_API_URL}conta-bancos`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getTodosAdmin = (token: string, filter = '') => {
    const url = `${process.env.REACT_APP_API_URL}user/admin${filter}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteAdmin = (token: string, id: string) => {
    const url = `${process.env.REACT_APP_API_URL}user/admin/remover/${id}`;

    return axios({
        method: 'delete',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postCriarAdmin = (token: string, email: string) => {
    const url = `${process.env.REACT_APP_API_URL}user/admin/criar-senha`;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            email
        }
    });
};

export const putAtualizarSenhaAdmin = (
    email: string,
    emailToken: string,
    password: string,
    password_confirmation: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}user/admin/atualizar-senha`;

    return axios({
        method: 'put',
        url,
        data: {
            email,
            token: emailToken,
            password,
            password_confirmation,
        }
    });
};

export const getTodosClientes = (token: string) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/cliente`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postCriarClientes = (
    token: string,
    conta_id: string,
    clienteData: clienteData
) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/cliente`;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {            
            conta_id,
            ...clienteData
        }
    });
};

export const putEditarClientes = (
    token: string,
    id: string,
    clienteData: clienteData
) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/cliente/${id}`;

    return axios({
        method: 'put',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: clienteData
    });
};

export const deleteClientes = (token: string, id: string) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/cliente/${id}`;

    return axios({
        method: 'delete',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

//Retorna dados cadastrais do CNPJ para concluir o cadastro de Parceiro
export const postContaVendedorNovaVida = (id: string) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/conta/nova-vida/${id}`;

    return axios({
        method: 'post',
        url,
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
    });
};

//Criar pre-cadastro de conta para parceiro (usuário não ADM)
export const postCriarPreCadastroPipefy = (
    token: string,
    data: postCriarPreCadastroVendedorProps
) => {
    const url = `${process.env.REACT_APP_API_URL}pipefy/pre-cadastro`;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    });
};

export const getEnviarEmailPreCadastro = (
    token: string,
    id: string
) => {
    const url = `${process.env.REACT_APP_API_URL}pipefy/email-pre-cadastro/${id}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

//Finalizar Cadastro de Conta pre-registrada Parceiro
export const putFinalizarCadastroPipefy = (
    id: string,
    data: postCriarContaVendedorProps
) => {
    const url = `${process.env.REACT_APP_API_URL}pipefy/finalizar-cadastro/${id}`;

    return axios({
        method: 'put',
        url,
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
        data
    });
};

export const postVerificarContatoEnviar = (
    documento: string,
    email: string,
    celular: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}verificar_contato/enviar_token`;

    return axios({
        method: 'post',
        url,
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
        data: {
            documento,
            email,
            celular,
            tipo: 'email',
        }
    });
};

export const postVerificarContatoReenviar = (
    documento: string,
    tipo: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}verificar_contato/reenviar_token`;

    return axios({
        method: 'post',
        url,
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
        data: {
            documento,
            tipo,
        }
    });
};

export const postVerificarContato = (
    documento: string,
    tipo: string,
    token: string,

) => {
    const url = `${process.env.REACT_APP_API_URL}verificar_contato/verificar_token`;

    return axios({
        method: 'post',
        url,
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
        data: {
            documento,            
            tipo,
            token
        }
    });
};

export const getTodosContaVendedor = (token: string, filters = '') => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/conta${filters}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getContaVendedor = (token: string, id: string) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/conta/${id}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteContaVendedor = (token: string, id: string) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/conta/${id}`;

    return axios({
        method: 'delete',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const putEditarContaVendedor = (
    token: string,
    data: putEditarContaVendedorProps
) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/conta/${data.id}`;
    
    return axios({
        method: 'put',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data
    });
};

// Criar conta para parceiro (usuário ADM)
export const postCriarContaVendedor = (
    token: string,
    data: postCriarContaVendedorProps
) => {
    const url = `${process.env.REACT_APP_API_URL}vendedor/conta`;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data
    });
};

export const postLogin = (email: string, senha: string) => {
    const url = `${process.env.REACT_APP_API_URL}auth/login`;

    return axios({
        method: 'post',
        url,
        data: {
            email,
            password: senha
        }
    });
}

export const postMe = (token: string) => {

    const url = `${process.env.REACT_APP_API_URL}auth/me`;
    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

export const postLogout = (token: string) => {

    const url = `${process.env.REACT_APP_API_URL}auth/logout`;
    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

//retorna apenas para parceiro, não para admin
export const getApiTokens = (token: string) => {

    const url = `${process.env.REACT_APP_API_URL}auth/me/api-tokens`;
    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

//Primeiro acesso
export const putUserAtualizarSenha = (
    email: string,
    emailToken: string,
    password: string,
    password_confirmation: string
) => {
    const url = `${process.env.REACT_APP_API_URL}user/atualizar-senha`;

    return axios({
        method: 'put',
        url,
        data: {
            email,
            token: emailToken,
            password,
            password_confirmation
        }
    });
};

//Recuperar senha
export const postUserCriarSenha = (email: string) => {
    const url = `${process.env.REACT_APP_API_URL}user/criar-senha`;

    return axios({
        method: 'post',
        url,
        data: {
            email
        }
    });
};

//Trocar de senha após pedir para recuperar senha
export const postUserResetarSenha = (
    email: string,
    password: string,
    password_confirmation: string,
    code: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}user/resetar-senha`;

    return axios({
        method: 'post',
        url,
        data: {
            email,
            password,
            password_confirmation,
            token: code,
        }
    });
};

export const postUserSolicitarNovaSenha = (
    email: string
) => {
    const url = `${process.env.REACT_APP_API_URL}user/solicitar-nova-senha`;

    return axios({
        method: 'post',
        url,
        data: {
            email,
        }
    });
};

export const getVerQrCode = (
    token: string,
    id: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}qrcode/qrcode/${id}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getTodosQrCode = (
    token: string,
    pagina = 1,
    filter = ''
) => {
    const url = `${process.env.REACT_APP_API_URL}qrcode/qrcode?page=${pagina}&${filter}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postCriarQrCode = (
    token: string,
    descricao: string,
    expiracao: string,
    conta_id: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}qrcode/qrcode`;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data:{
            descricao,
            expiracao,
            conta_id
        }            
    });
};

export const putEditarQrCode = (
    token: string,
    id: string,
    descricao: string,
    expiracao: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}qrcode/qrcode/${id}`;

    return axios({
        method: 'put',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            descricao,
            expiracao,
        }
    });
};

export const deleteDeletarQrCode = (
    token: string,
    id: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}qrcode/qrcode/${id}`;

    return axios({
        method: 'delete',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postCompartilharQrCodeEmail = (
    token: string,
    id: string,
    email: string,
) => {
    const url = `${process.env.REACT_APP_API_URL}qrcode/qrcode/${id}/enviar`;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data:{
            email
        }            
    });
};

export const getTodasTransacoes = (
    token: string,
    pagina = 1
) => {
    const url = `${process.env.REACT_APP_API_URL}jeitto-transacao/transacoes?page=${pagina}&sort=-created_at`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getFiltroTransacoes = (
    token: string,
    filtros: string
) => {
    const url = `${process.env.REACT_APP_API_URL}jeitto-transacao/transacoes/?${filtros}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postAgendarPagamentoTransacoes = (
    data: postAgendarPagamentoTransacoesProps,
) => {
    const url = `${process.env.REACT_APP_API_URL}jeitto-transacao/agendar-pagamento`;

    return axios({
        method: 'post',
        url,
        data
    });
};

export const getDashboard = (
    token: string,
    mes?: number
) => {
    const url = `${process.env.REACT_APP_API_URL}dashboard${mes ? `?mes=${mes}` : ''}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getBlogCategorias = (
    token: string,
    pagina = 1
) => {
    const url = `${process.env.REACT_APP_API_URL}posts/categorias?page=${pagina}`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getBlogCategoriasList = (
    token: string
) => {
    const url = `${process.env.REACT_APP_API_URL}posts/categorias/lista`;

    return axios({
        method: 'get',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteBlogCategoria = (
    token: string,
    id: number
) => {
    const url = `${process.env.REACT_APP_API_URL}posts/categorias/${id}`;

    return axios({
        method: 'delete',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const putBlogEditarCategoria = (
    token: string,
    id: number,
    nome: string,
    descricao: string
) => {
    const url = `${process.env.REACT_APP_API_URL}posts/categorias/${id}`;

    return axios({
        method: 'put',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            nome,
            descricao
        }
    });
};

export const postBlogNovaCategoria = (
    token: string,
    nome: string,
    descricao: string
) => {
    const url = `${process.env.REACT_APP_API_URL}posts/categorias`;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            nome,
            descricao
        }
    });
};

export const getBlogTags = (
  token: string,
  pagina = 1
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/tags?page=${pagina}`;

  return axios({
      method: 'get',
      url,
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};

export const getBlogTagsList = (
  token: string
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/tags/lista`;

  return axios({
      method: 'get',
      url,
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};

export const deleteBlogTag = (
  token: string,
  id: number
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/tags/${id}`;

  return axios({
      method: 'delete',
      url,
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};

export const postBlogNovaTag = (
  token: string,
  nome: string
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/tags`;

  return axios({
      method: 'post',
      url,
      headers: {
          Authorization: `Bearer ${token}`,
      },
      data: {
          tag: nome
      }
  });
};

export const getBlogPosts = (
  token: string,
  pagina = 1,
  filters = ''
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/posts?page=${pagina}${filters}`;

  return axios({
      method: 'get',
      url,
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};

export const getBlogPost = (
  token: string,
  id: string
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/posts/${id}`;

  return axios({
      method: 'get',
      url,
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};

export const deleteBlogPost = (
  token: string,
  id: number
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/posts/${id}`;

  return axios({
      method: 'delete',
      url,
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};

export const postBlogEditarPost = (
  token: string,
  id: string,
  data: newBlogPostProps,
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/posts/${id}`;

  const formData = new FormData();
    formData.append('titulo', data.titulo)
    formData.append('descricao', data.descricao)
    formData.append('conteudo', data.conteudo)
    formData.append('categoria_id', data.categoria_id)
    if(data.banner !== undefined && data.banner !== null && typeof(data.banner) !== typeof("string")){
        formData.append('banner', data.banner)
    }
    if(data.tags !== undefined && data.tags.length > 0){
        for (let index = 0; index < data.tags.length; index++) {
            const element = data.tags[index];
            formData.append(`tags[${index}][id]`, element.id );
        }
    }
    formData.append('_method', 'PUT')

  return axios({
      method: 'post',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData
    });
};

export const postBlogNovoPost = (
  token: string,
 data: newBlogPostProps
) => {
  const url = `${process.env.REACT_APP_API_URL}posts/posts`;

  const formData = new FormData();
    formData.append('titulo', data.titulo)
    formData.append('descricao', data.descricao)
    formData.append('conteudo', data.conteudo)
    formData.append('categoria_id', data.categoria_id)
    if(data.banner !== undefined){
        formData.append('banner', data.banner)
    }
    if(data.tags !== undefined && data.tags.length > 0){
        for (let index = 0; index < data.tags.length; index++) {
            const element = data.tags[index];
            formData.append(`tags[${index}][id]`, element.id );
        }
    }

  return axios({
      method: 'post',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData
  });
};
