import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { IoCaretBack, IoCaretForward, IoCloseCircle } from "react-icons/io5";
import { MdEdit, MdOutlineCheckCircleOutline } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { toast } from "react-toastify";

import { deleteContaVendedor, getEnviarEmailPreCadastro, getTodosContaVendedor, postCriarContaVendedor, postCriarPreCadastroPipefy, putEditarContaVendedor } from "../../../services/service";
import { postCriarContaVendedorProps, putEditarContaVendedorProps } from "../../../services/models";
import useAuth from "../../../hooks/useAuth";
import { CardsContainer, Container, MiniModal } from "./styles";

import { CustomInputMask } from "../../../components/form/CustomInputMask";
import { InputText } from "../../../components/form/InputText";
import { FullScreenModal } from "../../../components/FullScreenModal";
import { Loading } from "../../../components/Loading/Loading";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { CustomCurrencyInput } from "../../../components/form/CustomCurrencyInput";
import { Dropdown } from "../../../components/Dropdown";
import { SelectBanco } from "../../../components/SelectBanco";

interface vendedorData {    
    id: string,
    tipo: number | string,
    nome: string,
    nome_fantasia: string,
    razao_social: string,
    cnpj: string,
    celular: string,
    email: string,
    site: string,
    segmento: string,
    setor: string,
    vendas_mensais: string,
    conta_pai_id: string,
    vendedor_api_id: number | string,
    created_at: string,
    updated_at: string,
    deleted_at: null | string,
    partner_api_id: null | number | string,
    is_pipefy: boolean,
    status_pipefy: boolean,
    status_pipefy_label: string,
    status_partner_label: string,
    endereco: null | string,
    conta_bancaria: null | {
        id: string,
        conta_id: string,
        banco_nome: string,
        agencia: number | string,
        conta: number | string,
        tipo: number | string,
        created_at: string,
        updated_at: string,
        deleted_at: null | string,
        banco_cod: number | string,
        conta_digito: number | string
    }   
}

interface filtroCadastroProps {
    nome: string,
    cnpj: string,
    email: string,
    razao_social: string,
    status_pipefy: string | boolean,
    // tipo: string | number,
    // is_pipefy: string | boolean,
}

interface showVendedorData extends putEditarContaVendedorProps{
    conta_bancaria: {
        id?: string,
        conta_id?: string,
        banco_nome?: string,
        agencia?: string | number,
        conta?: string | number,
        tipo?: string | number,
        created_at?: string,
        updated_at?: string,
        deleted_at?: string | null,
        banco_cod?: string | number,
        conta_digito?: string | number,
    }
}

export function Parceiros() {
    const navigate = useNavigate();
    const {token, contaId} = useAuth();
    const [data, setData] = useState<vendedorData[]>([]);
    const [pagina, setPagina] = useState({current: 1, lastPage: 1});
    const [loading, setLoading] = useState(false);
    const [showEditarModal, setShowEditarModal] = useState({show: false, cadastro: {} as showVendedorData });
    const [showBuscarCadastroModal, setShowBuscarCadastroModal] = useState(false);
    const [showCriarCadastroModal, setShowCriarCadastroModal] = useState(false);
    const [showEditarCadastroModal, setShowEditarCadastroModal] = useState(false);
    const [showDeletarCadastroModal, setShowDeletarCadastroModal] = useState(false);
    const [filtroCadastro, setFiltroCadastro] = useState<filtroCadastroProps>({
        nome: '',
        cnpj: '',
        email: '',
        razao_social: '',
        status_pipefy: '',
        // tipo: '',
        // is_pipefy: '',
    })
    const [criarPreCadastro, setCriarPreCadastro] = useState(contaId === null ? false : true);


    const loadData = async() => {
        setLoading(true);
        try {
            const res = await getTodosContaVendedor(token);
            setData(res.data.data);
            setPagina({current: 1, lastPage: res.data.last_page});
            setFiltroCadastro({
                nome: '',
                cnpj: '',
                email: '',
                razao_social: '',
                status_pipefy: '',
                // tipo: '',
                // is_pipefy: '',
            });
            setLoading(false);

        } catch (err) {
            setLoading(false);
            console.log(err);

        }
    }

    const mudarPaginaData = async() => {
        setLoading(true);
        try {
            const res = await getTodosContaVendedor(
                token, 
                `?page=${pagina.current}&filter[nome]=${filtroCadastro.nome}&filter[razao_social]=${filtroCadastro.razao_social}&filter[email]=${filtroCadastro.email}&filter[status_pipefy]=${filtroCadastro.status_pipefy}&filter[cnpj]=${filtroCadastro.cnpj}`
            );
            setData(res.data.data);
            setPagina(prev => ({...prev, lastPage: res.data.last_page}));
            setLoading(false);

        } catch (err) {
            setLoading(false);
            console.log(err);

        }
    }

    const handleBusca = (
        e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
    ) => {
        e?.preventDefault();
        setShowBuscarCadastroModal(false);
        if(pagina.current !== 1){
            setPagina(prev => ({...prev, current: 1}));
        } else {
            mudarPaginaData();
        }
    }

    const handleLimparBusca = (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e?.preventDefault();
        setFiltroCadastro({
            nome: '',
            cnpj: '',
            email: '',
            razao_social: '',
            status_pipefy: '',
            // tipo: '',
            // is_pipefy: '',
        });
    }

    const handleEditarConta = async(id: string, data: putEditarContaVendedorProps) => {
        setLoading(true);
        try {
            const res = await putEditarContaVendedor(token, data);
            if(res.statusText === "OK"){
                await loadData();
                setLoading(false);
                toast.success('Conta atualizada com sucesso');
            } else {
                toast.error('Ocorreu um erro. Tente novamente mais tarde');
                setLoading(false);
            }

        } catch (err) {
            toast.error('Ocorreu um erro. Tente novamente mais tarde');
            setLoading(false);
            console.log(err);

        }
    }

    const handleDeletarConta = async(id: string) => {
        setLoading(true);
        try {
            const res = await deleteContaVendedor(token, id);
            if(res.statusText === "OK"){
                await loadData();
                setLoading(false);
                toast.success('Conta deletada com sucesso');
            } else {
                toast.error('Ocorreu um erro. Tente novamente mais tarde');
                setLoading(false);
            }

        } catch (err) {
            toast.error('Ocorreu um erro. Tente novamente mais tarde');
            setLoading(false);
            console.log(err);

        }
    }

    const handleCriarConta = async(data: postCriarContaVendedorProps) => {
        setLoading(true);
        if(criarPreCadastro) {
            try {
                await postCriarPreCadastroPipefy(token, data);
                await loadData();
                setLoading(false);
                toast.success('Pré-cadastro criado com sucesso');

            } catch (err) {
                toast.error(
                    //@ts-ignore
                    err.response?.data?.data ? Object.values(err.response.data.data).join(' ') :
                    'Ocorreu um erro. Tente novamente mais tarde'
                );
                setLoading(false);
                console.log(err);
            }
        }else {
            try {
                await postCriarContaVendedor(token, data);
                await loadData();
                setLoading(false);
                toast.success('Conta criada com sucesso');
            } catch (err) {
                toast.error(
                    //@ts-ignore
                    err.response?.data?.data ? Object.values(err.response.data.data).join(' ') :
                    'Ocorreu um erro. Tente novamente mais tarde'
                );
                setLoading(false);
                console.log(err);
            }
        }
    }

    // const parseData = (data: string) => {
    //     if(data === null || data === undefined || data === ''){
    //         return '';
    //     } else {
    //         return new Date(data).toLocaleDateString('pt-BR');
    //     }
    // }

    // const parseDataForRequest = (data: string) => {
    //     if(data === null || data === undefined || data === ''){
    //         return '';
    //     } else {
    //         const [D, M, A] = data.split('/')
    //         return `${A}-${M}-${D}`
    //     }
    // }

    const parseParceiroGetToPut = (obj: vendedorData) => {
        return(
            {
                id: obj.id,
                email: obj.email,
                conta: {
                    razao_social: obj.razao_social,
                    cnpj: obj.cnpj,
                    nome : obj.nome,
                    celular : obj.celular ,
                    nome_fantasia: obj.nome_fantasia,
                    site: obj.site,
                    segmento: obj.segmento,
                    setor: obj.setor,
                    vendas_mensais: obj.vendas_mensais,
                },
                conta_bancaria: {
                    id: obj.conta_bancaria?.id,
                    conta_id: obj.conta_bancaria?.conta_id,
                    banco_nome: obj.conta_bancaria?.banco_nome,
                    agencia: obj.conta_bancaria?.agencia,
                    conta: obj.conta_bancaria?.conta,
                    tipo: obj.conta_bancaria?.tipo,
                    created_at: obj.conta_bancaria?.created_at,
                    updated_at: obj.conta_bancaria?.updated_at,
                    deleted_at: obj.conta_bancaria?.deleted_at,
                    banco_cod: obj.conta_bancaria?.banco_cod,
                    conta_digito: obj.conta_bancaria?.conta_digito,
                },
            }
        )
    }

    const CriarCadastroModal = () => {
        const [criarContaData, setCriarContaData] = useState({
            conta_bancaria: {
                tipo: 1
            }
        } as postCriarContaVendedorProps)

        return(
            <FullScreenModal show={showCriarCadastroModal} setShow={setShowCriarCadastroModal} >
                <form>
                    {contaId === null ?
                        <div className="limparFiltroButtonContainer">
                            <button
                                type="button"
                                onClick={e => {
                                    e.preventDefault();
                                    setCriarPreCadastro(prev => !prev)
                                }}
                                >
                            {!criarPreCadastro ? 'Criar Pré-cadastro' : 'Criar Conta'}
                            </button>
                        </div>
                    : null
                    }

                    <h2>{criarPreCadastro ? 'Criar Pré-cadastro' : 'Criar Conta'}</h2>
                    <h3>Dados cadastrais</h3>
                        <InputText
                            value={criarContaData.conta?.nome}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        nome: e.target.value
                                    }
                                    
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Nome"}
                        />
                        <InputText
                            value={criarContaData.conta?.razao_social}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        razao_social: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Razão Social"}
                        />
                        <InputText
                            value={criarContaData.conta?.nome_fantasia}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        nome_fantasia: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Nome Fantasia"}
                        />

                    <div className="oneLineInputs" >                        
                    <CustomInputMask
                        value={criarContaData.conta?.cnpj}
                        onChange={(e) => {
                            setCriarContaData( prev => ({
                                ...prev,
                                conta: {
                                        ...prev.conta,
                                    cnpj: e.target.value
                                }
                            }))
                        }}
                        className="inputStyle margin-right"
                        placeholder={"CNPJ"}
                        mask={"99.999.999/9999-99"}
                    />

                    <CustomCurrencyInput
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        prefix='R$'
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        decimalScale={2}
                        maxLength={10}
                        value={criarContaData.conta?.vendas_mensais}
                        onValueChange={(value) => 
                            setCriarContaData(
                                // @ts-ignore
                                prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        vendas_mensais: value
                                    }
                                })
                            )
                        }
                        className="inputStyle"
                        placeholder="Vendas Mensais"
                    />
                    </div>

                    <div className="oneLineInputs" >
                        <InputText
                            value={criarContaData.conta?.setor}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        setor: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle margin-right"
                            placeholder={"Setor"}
                        />
                        <InputText
                            value={criarContaData.conta?.segmento}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        segmento: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Segmento"}
                        />
                    </div>

                    <h3>Dados de contato</h3>
                        <InputText
                            value={criarContaData.email}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    email: e.target.value
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Email"}
                        />
                    <div className="oneLineInputs" >
                        <CustomInputMask
                            value={criarContaData.conta?.celular}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        celular: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle margin-right"
                            placeholder={"Celular"}
                            mask={"(99) 99999-9999"}
                        />
                        <InputText
                            value={criarContaData.conta?.site}
                            onChange={(e) => {
                                setCriarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        site: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Site"}
                        />
                    </div>
                    
                    {!criarPreCadastro ?
                    <>
                        <h3>Dados bancários</h3>
                        <div className="oneLineInputs" >
                        <SelectBanco
                            setBancoNome={(arg) => {
                                setCriarContaData(prev => ({
                                    ...prev,
                                    conta_bancaria: {
                                        ...prev.conta_bancaria,
                                        banco_nome: arg
                                    }
                                }))
                            }}
                            setBancoCod={(arg) => {
                                setCriarContaData(prev => ({
                                    ...prev,
                                    conta_bancaria: {
                                        ...prev.conta_bancaria,
                                        banco_cod: arg
                                    }
                                }))
                            }}
                        />
                            {/* <InputText
                                value={criarContaData.conta_bancaria?.banco_nome}
                                onChange={(e) => {
                                    setCriarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            banco_nome: e.target.value
                                        }
                                    }))
                                }}
                                className="inputStyle margin-right"
                                placeholder={"Banco"}
                            /> */}
                            {/* <CustomInputMask
                                value={criarContaData.conta_bancaria?.banco_cod}
                                onChange={(e) => {
                                    setCriarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            banco_cod: e.target.value
                                        }
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"Código do banco"}
                                mask={"99"}
                            /> */}
                        </div>
                        <div className="oneLineInputs" >
                            <CustomInputMask
                                value={criarContaData.conta_bancaria?.agencia}
                                onChange={(e) => {
                                    setCriarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            agencia: e.target.value
                                        }
                                    }))
                                }}
                                className="inputStyle margin-right"
                                placeholder={"Agência"}
                                mask={"9999"}
                                // @ts-ignore
                                maskChar={null}
                            />
                            <CustomInputMask
                                value={criarContaData.conta_bancaria?.conta}
                                onChange={(e) => {
                                    setCriarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            conta: e.target.value
                                        }
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"Conta"}
                                mask={"999999999999999"}
                                // @ts-ignore
                                maskChar={null}
                            />
                            <div>
                            <CustomInputMask
                                value={criarContaData.conta_bancaria?.conta_digito}
                                onChange={(e) => {
                                    setCriarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            conta_digito: e.target.value
                                        }
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"Dígito"}
                                mask={"9"}
                                // @ts-ignore
                                maskChar={0}
                            />
                            </div>
                        </div>
                        <div className="checkboxContainer" >
                            <label>
                            <input type="checkbox"
                                checked={criarContaData.conta_bancaria?.tipo === 1 ? true : false}
                                onChange={() =>
                                    setCriarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            tipo: 1
                                        }
                                    }))
                                }
                            />
                            Conta Corrente
                            </label>
                            <label>
                            <input type="checkbox"
                                checked={criarContaData.conta_bancaria?.tipo === 2 ? true : false}
                                onChange={() =>
                                    setCriarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            tipo: 2
                                        }
                                    }))
                                }
                            />
                            Conta Poupança
                            </label>                        
                        </div>
                    </>
                    : null}

                    <OutlinedButton
                        style={{alignSelf: 'center'}}
                        show={showCriarCadastroModal}
                        onClick={e => {
                            e.preventDefault();
                            setShowCriarCadastroModal(false);
                            handleCriarConta(criarContaData);
                        }}
                    >
                        Criar Conta
                    </OutlinedButton>
                </form>
            </FullScreenModal>
        )
    }

    const EditarCadastroModal = () => {
        const [editarContaData, setEditarContaData] = useState(showEditarModal.cadastro);
        
        return(
            <FullScreenModal show={showEditarCadastroModal} setShow={setShowEditarCadastroModal} >
                <form>                        
                    <h3>Dados cadastrais</h3>
                        <InputText
                            value={editarContaData.conta.nome}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        nome: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Nome"}
                        />
                        <InputText
                            value={editarContaData.conta.razao_social}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        razao_social: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Razão Social"}
                        />
                        <InputText
                            value={editarContaData.conta.nome_fantasia}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        nome_fantasia: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Nome Fantasia"}
                        />

                    <div className="oneLineInputs" >                        
                    <CustomInputMask
                        value={editarContaData.conta.cnpj}
                        onChange={(e) => {
                            setEditarContaData( prev => ({
                                ...prev,
                                conta: {
                                        ...prev.conta,
                                    cnpj: e.target.value
                                }
                            }))
                        }}
                        className="inputStyle margin-right"
                        placeholder={"CNPJ"}
                        mask={"99.999.999/9999-99"}
                    />

                    <CustomCurrencyInput
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        prefix='R$'
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        decimalScale={2}
                        maxLength={10}
                        value={editarContaData.conta.vendas_mensais}
                        onValueChange={(value) => 
                            setEditarContaData(
                                // @ts-ignore
                                prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        vendas_mensais: value 
                                    }
                                })                                    
                            )                                
                        }
                        className="inputStyle"
                        placeholder="Vendas Mensais"
                    />
                    </div>

                    <div className="oneLineInputs" >
                        <InputText
                            value={editarContaData.conta.setor}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        setor: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle margin-right"
                            placeholder={"Setor"}
                        />
                        <InputText
                            value={editarContaData.conta.segmento}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        segmento: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Segmento"}
                        />
                    </div>

                    <h3>Dados de contato</h3>
                        <InputText
                            value={editarContaData.email}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    email: e.target.value
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Email"}
                        />
                    <div className="oneLineInputs" >
                        <CustomInputMask
                            value={editarContaData.conta.celular}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        celular: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle margin-right"
                            placeholder={"Celular"}
                            mask={"(99) 99999-9999"}
                        />
                        <InputText
                            value={editarContaData.conta.site}
                            onChange={(e) => {
                                setEditarContaData( prev => ({
                                    ...prev,
                                    conta: {
                                        ...prev.conta,
                                        site: e.target.value
                                    }
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Site"}
                        />
                    </div>

                    <h3>Dados bancários</h3>
                        <div className="oneLineInputs" >
                        {/* <SelectBanco
                            setBancoNome={(arg) => {
                                setEditarContaData(prev => ({
                                    ...prev,
                                    // conta_bancaria: {
                                    //     ...prev.conta_bancaria,
                                    //     banco_nome: arg
                                    // }
                                }))
                            }}
                            setBancoCod={(arg) => {
                                setEditarContaData(prev => ({
                                    ...prev,
                                    // conta_bancaria: {
                                    //     ...prev.conta_bancaria,
                                    //     banco_cod: arg
                                    // }
                                }))
                            }}
                        /> */}
                            <InputText
                                className="inputStyle margin-right"
                                placeholder={"Banco"}
                                value={editarContaData.conta_bancaria?.banco_cod ? `${editarContaData.conta_bancaria?.banco_cod} ${editarContaData.conta_bancaria?.banco_nome}` : ''}
                                // onChange={(e) => {
                                //     setEditarContaData( prev => ({
                                //         ...prev,
                                //         conta_bancaria: {
                                //             ...prev.conta_bancaria,
                                //             banco_nome: e.target.value
                                //         }
                                //     }))
                                // }}
                                disabled
                            />
                            {/* <CustomInputMask
                                value={criarContaData.conta_bancaria?.banco_cod}
                                onChange={(e) => {
                                    setEditarContaData( prev => ({
                                        ...prev,
                                        conta_bancaria: {
                                            ...prev.conta_bancaria,
                                            banco_cod: e.target.value
                                        }
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"Código do banco"}
                                mask={"99"}
                            /> */}
                        </div>
                        <div className="oneLineInputs" >
                            <CustomInputMask
                                className="inputStyle margin-right"
                                placeholder={"Agência"}
                                value={editarContaData.conta_bancaria?.agencia}
                                // onChange={(e) => {
                                //     setEditarContaData( prev => ({
                                //         ...prev,
                                //         conta_bancaria: {
                                //             ...prev.conta_bancaria,
                                //             agencia: e.target.value
                                //         }
                                //     }))
                                // }}
                                mask={"9999"}
                                // @ts-ignore
                                maskChar={null}
                                disabled
                            />
                            <CustomInputMask
                                className="inputStyle"
                                placeholder={"Conta"}
                                value={editarContaData.conta_bancaria?.conta}
                                // onChange={(e) => {
                                //     setEditarContaData( prev => ({
                                //         ...prev,
                                //         conta_bancaria: {
                                //             ...prev.conta_bancaria,
                                //             conta: e.target.value
                                //         }
                                //     }))
                                // }}
                                mask={"999999999999999"}
                                // @ts-ignore
                                maskChar={null}
                                disabled
                            />
                            <div>
                            <CustomInputMask
                                className="inputStyle"
                                placeholder={"Dígito"}
                                value={editarContaData.conta_bancaria?.conta_digito}
                                // onChange={(e) => {
                                //     setEditarContaData( prev => ({
                                //         ...prev,
                                //         conta_bancaria: {
                                //             ...prev.conta_bancaria,
                                //             conta_digito: e.target.value
                                //         }
                                //     }))
                                // }}
                                mask={"9"}
                                // @ts-ignore
                                maskChar={0}
                                disabled
                            />
                            </div>
                        </div>
                        <div className="checkboxContainer" >
                            <label>
                            <input type="checkbox"
                                checked={editarContaData.conta_bancaria?.tipo === 1 ? true : false}
                                // onChange={() =>
                                //     setEditarContaData( prev => ({
                                //         ...prev,
                                //         conta_bancaria: {
                                //             ...prev.conta_bancaria,
                                //             tipo: 1
                                //         }
                                //     }))
                                // }
                                disabled
                            />
                            Conta Corrente
                            </label>
                            <label>
                            <input type="checkbox"
                                checked={editarContaData.conta_bancaria?.tipo === 2 ? true : false}
                                // onChange={() =>
                                //     setEditarContaData( prev => ({
                                //         ...prev,
                                //         conta_bancaria: {
                                //             ...prev.conta_bancaria,
                                //             tipo: 2
                                //         }
                                //     }))
                                // }
                                disabled
                            />
                            Conta Poupança
                            </label>                        
                        </div>

                    <OutlinedButton
                        style={{alignSelf: 'center'}}
                        show={showEditarCadastroModal}
                        onClick={e => {
                            e.preventDefault();
                            setShowEditarCadastroModal(false);
                            handleEditarConta(editarContaData.id, {...editarContaData});
                        }}
                    >
                        Atualizar Conta
                    </OutlinedButton>
                </form>
            </FullScreenModal>
        )
    }

    const DeletarCadastroModal = () => {
        return(
            <FullScreenModal show={showDeletarCadastroModal} setShow={setShowDeletarCadastroModal} >
                <h2>Deletar uma conta é irreversível. Você deseja deletar esta conta?</h2>
                <form>
                    <h4>Nome</h4>
                    <InputText
                        value={showEditarModal.cadastro.conta.nome}
                        className="inputStyle"
                        placeholder={"Nome"}
                        disabled={true}
                    />

                    <h4>Nome Fantasia</h4>
                    <InputText
                        value={showEditarModal.cadastro.conta.nome_fantasia}
                        className="inputStyle"
                        placeholder={"Nome Fantasia"}
                        disabled={true}
                    />

                    <h4>Razão Social</h4>
                    <InputText
                        value={showEditarModal.cadastro.conta.razao_social}
                        className="inputStyle"
                        placeholder={"Razao Social"}
                        disabled={true}
                    />

                    <h4>Email</h4>
                    <InputText
                        value={showEditarModal.cadastro.email}
                        className="inputStyle"
                        placeholder={"Email"}
                        disabled={true}
                    />

                    <OutlinedButton
                        style={{alignSelf: 'center'}}
                        show={showDeletarCadastroModal}
                        onClick={e => {
                            e.preventDefault();
                            setShowDeletarCadastroModal(false);
                            handleDeletarConta(showEditarModal.cadastro.id);
                        }}
                    >
                        Deletar
                    </OutlinedButton>
                </form>
            </FullScreenModal>
        )
    }

    const MiniModalManager = ({obj}: any) => {
        const handleCopyLink = (id: string) => {
            try {
                if( navigator.clipboard !== undefined){
                    navigator.clipboard.writeText(`${window.location.origin.toString()}/cadastro-pre/${id}`);
                    toast.success(`O link para finalizar o cadastro foi copiado!`);
                } else {
                    const textArea = document.createElement("textarea");
                    textArea.value = `${window.location.origin.toString()}/cadastro-pre/${id}`;
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
    
                    try {
                        document.execCommand('copy');
                        toast.success(`O link para finalizar o cadastro foi copiado!\ncadastro-pre/${id}`);
                    } catch (err) {
                        toast.warn(`Não foi possível copiar o link`);
                    }
                    finally{
                        document.body.removeChild(textArea);
                    }
                }
            } catch (err) {
                console.log(err);
                toast.warn(`Não foi possível copiar o link`);
            }
        }

        const handleEnviarEmail = async (id: string) => {
            setLoading(true);
            try {
                await getEnviarEmailPreCadastro(token, id);
                setLoading(false);
                toast.success('Email enviado com sucesso');
            } catch (err) {
                toast.error('Ocorreu um erro ao enviar o email. Tente novamente mais tarde');
                setLoading(false);
                console.log(err);
            }
        }

        if(obj.status_pipefy){
            return(
                <MiniModal show={showEditarModal.show === true ? showEditarModal.cadastro.id === obj.id : false} >
                    <MdEdit
                        size={20}
                        fill="#340950"
                        onClick={() => setShowEditarModal({show: true, cadastro: parseParceiroGetToPut(obj)})} 
                    />
                    <GrTransaction                 
                        size={20}
                        fill="#340950"
                        onClick={() => navigate(`/dashboard/operacao?filter[conta_id]=${obj.id}`)} 
                    />
                    <div>
                    <IoCloseCircle size={20} onClick={() => setShowEditarModal({show: false, cadastro: {id:'', conta: {}, conta_bancaria: {}}})} style={{position: 'absolute', top: 5, right: 5}} />
                        <button
                            className="optionsButton"
                            onClick={e => {
                                e.preventDefault();
                                setShowEditarCadastroModal(true);
                                setShowEditarModal({show: false, cadastro: parseParceiroGetToPut(obj)});
                            }}
                        >
                            Editar                           
                        </button>
                        <button
                            className="optionsButton"
                            onClick={e => {
                                e.preventDefault();
                                setShowDeletarCadastroModal(true);
                                setShowEditarModal({show: false, cadastro: parseParceiroGetToPut(obj)});
                            }}
                            
                        >
                            Deletar                           
                        </button>
                    </div>
                </MiniModal>
            )
        } else{
            return (
                <MiniModal show={showEditarModal.show === true ? showEditarModal.cadastro.id === obj.id : false} >
                    <MdOutlineCheckCircleOutline                                            
                        size={20}
                        fill="#340950"
                        onClick={() => setShowEditarModal({show: true, cadastro: parseParceiroGetToPut(obj)})} 
                    />
                    <div>
                    <IoCloseCircle size={20} onClick={() => setShowEditarModal({show: false, cadastro: {id:'', conta: {}, conta_bancaria: {}}})} style={{position: 'absolute', top: 5, right: 5}} />
                        <button
                            className="optionsButton"
                            onClick={e => {
                                e.preventDefault();
                                handleCopyLink(obj.id);
                                // setShowEditarCadastroModal(true);
                                setShowEditarModal({show: false, cadastro: parseParceiroGetToPut(obj)});
                            }}
                        >
                            Copiar link                         
                        </button>
                        <button
                            className="optionsButton"
                            onClick={e => {
                                e.preventDefault();
                                handleEnviarEmail(obj.id);
                                // setShowDeletarCadastroModal(true);
                                setShowEditarModal({show: false, cadastro: parseParceiroGetToPut(obj)});
                            }}
                            
                        >
                            Enviar lembrete                           
                        </button>
                    </div>
                </MiniModal>
            )
        }
    }

    useEffect(() => {
        loadData();
    },[])
    
    useEffect(() => {
        mudarPaginaData();
    },[pagina.current])

    return (
        <Container>
            <header className="headerTabela">
                <h1 className="nomePagina">PARCEIROS</h1>               
            </header>
            <CardsContainer>
                {true &&
                <div className="card">
                  <p className="title" >000</p>
                  <p className="text" >PENDENTE</p>
                </div>
                }
                {true &&
                <div className="card">
                    <p className="title" >000</p>
                    <p className="text" >APROVADO</p>
                </div>
                }                
                {true &&
                <div className="card">
                    <p className="title" >000</p>
                    <p className="text" >ATIVO</p>
                </div>
                }
                {true &&
                <div className="card">
                    <p className="title" >000</p>
                    <p className="text" >MÉDIA DIÁRIA</p>
                </div>
                }
                {true &&
                <div className="card">
                    <p className="title" >000</p>
                    <p className="text" >TOTAL</p>
                </div>
                }
            </CardsContainer>
            <main>
                <div className="tabelaContainer">
                    <div className="pesquisar">
                        <OutlinedButton 
                            show={true}
                            onClick={() => setShowBuscarCadastroModal(true)}
                        >
                            BUSCAR CONTA
                        </OutlinedButton>
                        <OutlinedButton 
                            show={true}
                            onClick={() => setShowCriarCadastroModal(true)}
                            >
                            CRIAR CONTA
                        </OutlinedButton>
                    </div>

                    {!loading ?
                    <table cellPadding={20} cellSpacing={10}>
                        <thead>
                            <tr>
                                <td>NOME</td>
                                <td>RAZÃO SOCIAL</td>
                                <td>EMAIL</td>
                                <td className="centerColumn" >STATUS</td>
                                <td className="centerColumn" >CNPJ</td>
                                <td className="centerColumn" >ID</td>
                                <td className="centerColumn" >CONTATO</td>
                                <td className="ultimo"></td>
                            </tr>
                        </thead>

                        <tbody>
                                {data?.map((obj,idx) => (
                                <tr key={idx}>
                                    <td style={{alignContent: 'stretch'}} >{obj.nome}</td>
                                    <td style={{alignContent: 'stretch'}} >{obj.razao_social}</td>
                                    <td style={{alignContent: 'stretch'}} >{obj.email}</td>
                                    <td className={`centerColumn status-${obj.status_pipefy}`} style={{alignContent: 'stretch'}} >{obj.status_pipefy === true ? 'Finalizado' : 'Pendente'}</td>
                                    <td className="centerColumn" style={{alignContent: 'stretch'}} >{obj.cnpj}</td>
                                    <td className="centerColumn" style={{alignContent: 'stretch'}} >{obj.vendedor_api_id}</td>                                    
                                    <td className="centerColumn" style={{alignContent: 'stretch'}} >{obj.celular}</td>                                    
                                    <td className="ultimo">
                                        <MiniModalManager obj={obj} />
                                    </td>
                                </tr>
                                ))}
                        </tbody>
                    </table>
                    : null}

                    {pagina.lastPage > 1 ?
                        <div className="paginationContainer">                    
                            <IoCaretBack
                                size={30}
                                onClick={() => setPagina(prev => ({...prev, current: prev.current - 1}) )}
                                style={pagina.current > 1 ? {visibility: 'visible'} : {visibility: 'hidden'}}
                            />                 
                            <span className="pageNumber" >{pagina.current}</span>
                            <IoCaretForward
                                size={30}
                                onClick={() => setPagina(prev => ({...prev, current: prev.current + 1}) )}
                                style={pagina.current < pagina.lastPage ? {visibility: 'visible'} : {visibility: 'hidden'}}
                            />
                        </div>
                    :
                        null
                    }
                    
                </div>
            </main>

            {/* //////BuscarCadastroModal////// */}
            <FullScreenModal show={showBuscarCadastroModal} setShow={setShowBuscarCadastroModal} >
                <form onSubmit={handleBusca}>
                    <div className="limparFiltroButtonContainer" >
                        <button 
                            onClick={handleLimparBusca}
                            type="button"
                        >
                            Limpar filtros
                        </button>
                    </div>
                    <h3>Filtrar</h3>                        
                        <InputText
                            value={filtroCadastro.nome}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    nome: e.target.value
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Nome"}
                        />                        
                        <InputText
                            value={filtroCadastro.razao_social}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    razao_social: e.target.value
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Razão social"}
                        />                        
                        <InputText
                            value={filtroCadastro.email}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    email: e.target.value
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Email"}
                        />                        
                    <div className="oneLineInputs">                            
                        {/* <CustomInputMask
                            value={filtroCadastro.contato}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    contato: e.target.value
                                }))
                            }}
                            className="inputStyle margin-right"
                            placeholder={"Contato"}
                            mask={"(99) 99999-9999"}
                            //@ts-ignore
                            maskChar={null}
                        />                             */}
                        <CustomInputMask
                            value={filtroCadastro.cnpj}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    cnpj: e.target.value
                                }))
                            }}
                            className="inputStyle margin-right"
                            placeholder={"CNPJ"}
                            mask={"99.999.999/9999-99"}
                            //@ts-ignore
                            maskChar={null}
                        />
                        {/* <CustomInputMask
                            value={filtroCadastro.id}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    id: e.target.value
                                }))
                            }}
                            className="inputStyle margin-right"
                            placeholder={"ID"}
                            mask={"999999"}
                            //@ts-ignore
                            maskChar={null}
                        />*/}
                        <div className="buttonInput" >
                            <Dropdown
                                title={
                                    filtroCadastro.status_pipefy === ''
                                        ? 'Status'
                                        : filtroCadastro.status_pipefy === true
                                            ? 'Finalizado'
                                            : 'Pendente'
                                }
                                textColor='#340950'
                                content={['Pendente', 'Finalizado']}
                                callback={(arg) =>
                                    setFiltroCadastro( prev => ({
                                        ...prev,
                                        status_pipefy: (arg === 'Pendente' ? false : true)
                                    }))
                                }
                            />
                        </div>                        
                    </div>

                    <OutlinedButton
                        style={{alignSelf: 'center'}}
                        show={showBuscarCadastroModal}
                        onClick={handleBusca}
                        type="submit"
                    >
                        Buscar
                    </OutlinedButton>
                </form>
            </FullScreenModal>

            {showCriarCadastroModal ? <CriarCadastroModal /> : null}
            {showEditarCadastroModal ? <EditarCadastroModal /> : null}
            {showDeletarCadastroModal ? <DeletarCadastroModal /> : null}
            <Loading isLoading={loading} />
        </Container>
    )
}