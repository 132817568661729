import styled from 'styled-components';

export const Container = styled.main`
  margin: 30px 0px;
  max-width: 60%;

  @media only screen and (max-width: 900px) {
    max-width: 90%;
  }

  .busca{
    font-size: 1.33rem;
    color: var(--laranja-500);
    font-weight: 900;
    margin-bottom: 15px;
  }

`

export const PostContainer = styled.section`
  display: flex;
  margin-bottom: 25px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }

  .leftContainer{
    width: 300px;
    height: 169px;

    img{
      width: 300px;
      height: 169px;
      object-fit: contain;
      
    }

    @media only screen and (max-width: 900px) {
      width: 80vw;
      height: 45vw;

      img{
        width: 80vw;
        height: 45vw;
        
      }
    }
    
  }

  .rightContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;

    .categoria{
      color: var(--laranja-500);
      font-size: 0.85rem;
    }
    
    .titulo{
      font-size: 1.75rem;
      color: var(--thead-color);

      &:hover{
        color: var(--roxo-700);
        cursor: pointer;
      }
      
    }
    
    .data{
      font-size: 0.85rem;
      margin-top: 20px;

    }

    @media only screen and (max-width: 900px) {
      margin-top: 5px;
      margin-left: 0px;

      .data{
        margin-top: 10px;

      }
    }

  }

`