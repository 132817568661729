import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FiDelete } from "react-icons/fi";
import { toast } from "react-toastify";

import useAuth from "../../../hooks/useAuth";
import { deleteAdmin, getTodosAdmin, postCriarAdmin } from "../../../services/service";
import { Container } from "./styles";

import { InputText } from "../../../components/form/InputText";
import { FullScreenModal } from "../../../components/FullScreenModal";
import { Loading } from "../../../components/Loading/Loading";
import { OutlinedButton } from "../../../components/OutlinedButton";
import PageNavigation from "../../../components/PageNavigation";

interface vendedorData {    
    id: string,
    tipo: string,
    nome: string,
    nome_fantasia: string,
    razao_social: string,
    cnpj: string,
    celular: string,
    email: string,
    site: string,
    segmento: string,
    setor: string,
    vendas_mensais: string,
    conta_pai_id: string | null,
    vendedor_api_id: string | null,
    created_at: string,
    updated_at: string,
    deleted_at: string | null,
    endereco: string | null,
    conta_bancaria: string | null,
    documentos: []    
}

export function Administrativo() {
    const navigate = useNavigate();
    const {token, contaId} = useAuth();
    const [data, setData] = useState<vendedorData[]>([]);
    const [pagina, setPagina] = useState({current: 1, last: 1});
    const [loading, setLoading] = useState(false);
    const [showEditarModal, setShowEditarModal] = useState({show: false, cadastro: {id: '', email: '', created_at: ''} });
    const [showBuscarCadastroModal, setShowBuscarCadastroModal] = useState(false);
    const [showCriarCadastroModal, setShowCriarCadastroModal] = useState(false);
    // const [showEditarCadastroModal, setShowEditarCadastroModal] = useState(false);
    const [showDeletarCadastroModal, setShowDeletarCadastroModal] = useState(false);
    const [filtroCadastro, setFiltroCadastro] = useState({
        email: '',
        id: '',
    })

    const loadData = async() => {
        setLoading(true);
        try {
            const res = await getTodosAdmin(token);
            setData(res.data.data);
            setPagina({current: 1, last: res.data.last_page});
            setFiltroCadastro({
                email: '',
                id: ''
            });
            setLoading(false);

        } catch (err) {
            setLoading(false);
            console.log(err);

        }
    }

    const mudarPaginaData = async() => {
        setLoading(true);
        try {
            const res = await getTodosAdmin(
                token, 
                `?page=${pagina.current}&filter[email]=${filtroCadastro.email}`
                // `?page=${pagina.current}&filter[email]=${filtroCadastro.email}&filter[id]=${filtroCadastro.id}`
            );
            setData(res.data.data);
            setPagina(prev => ({...prev, last: res.data.last_page}));
            setLoading(false);

        } catch (err) {
            setLoading(false);
            console.log(err);

        }
    }

    // const handleEditarConta = async(id: string, data: putEditarContaVendedorProps) => {
    //     setLoading(true);
    //     try {
    //         const res = await putEditarContaVendedor(token, id, data);
    //         if(res.statusText === "OK"){
    //             await loadData();
    //             setLoading(false);
    //             toast.success('Conta atualizada com sucesso');
    //         } else {
    //             toast.error('Ocorreu um erro. Tente novamente mais tarde');
    //             setLoading(false);
    //         }

    //     } catch (err) {
    //         toast.error('Ocorreu um erro. Tente novamente mais tarde');
    //         setLoading(false);
    //         console.log(err);

    //     }
    // }

    const handleDeletarConta = async(id: string) => {
        setLoading(true);
        try {
            const res = await deleteAdmin(token, id);
            if(res.statusText === "OK"){
                await loadData();
                setLoading(false);
                toast.success('Conta deletada com sucesso');
            } else {
                toast.error('Ocorreu um erro. Tente novamente mais tarde');
                setLoading(false);
            }

        } catch (err) {
            toast.error('Ocorreu um erro. Tente novamente mais tarde');
            setLoading(false);
            console.log(err);

        }
    }

    const handleCriarConta = async(email: string) => {
        setLoading(true);
        try {
            const res = await postCriarAdmin(token, email);
            console.log(res)
            if(res.statusText === "OK"){
                await loadData();
                setLoading(false);
                toast.success(`${res.data.message}`);
            } else {
                toast.error('Ocorreu um erro. Tente novamente mais tarde');
                setLoading(false);
            }

        } catch (err) {
            console.log(err);
            //@ts-ignore
            toast.error('Ocorreu um erro. Tente novamente mais tarde');
            setLoading(false);

        }
    }

    const parseData = (data: string) => {
        if(data === null || data === undefined || data === ''){
            return '';
        } else {
            return new Date(data).toLocaleDateString('pt-BR');
        }
    }

    // const parseDataForRequest = (data: string) => {
    //     if(data === null || data === undefined || data === ''){
    //         return '';
    //     } else {
    //         const [D, M, A] = data.split('/')
    //         return `${A}-${M}-${D}`
    //     }
    // }

    const CriarCadastroModal = () => {
        const [criarContaData, setCriarContaData] = useState('');

        return(
            <FullScreenModal show={showCriarCadastroModal} setShow={setShowCriarCadastroModal} >
                <form>                        
                    <h3>Cadastre um endereço de email para criar a conta</h3>
                    <InputText
                        value={criarContaData}
                        onChange={(e) => {
                            setCriarContaData( 
                                e.target.value
                            )
                        }}
                        className="inputStyle"
                        placeholder={"Email"}
                    />
                    <OutlinedButton
                        style={{alignSelf: 'center'}}
                        show={showCriarCadastroModal}
                        onClick={e => {
                            e.preventDefault();
                            setShowCriarCadastroModal(false);
                            handleCriarConta(criarContaData);
                        }}
                    >
                        Criar Conta
                    </OutlinedButton>
                </form>
            </FullScreenModal>
        )
    }

    // const EditarCadastroModal = () => {
    //     const [editarContaData, setEditarContaData] = useState(showEditarModal.cadastro)

    //     return(
    //         <FullScreenModal show={showEditarCadastroModal} setShow={setShowEditarCadastroModal} >
    //             <form>                        
    //                 <h3>Dados cadastrais</h3>
    //                     <InputText
    //                         value={editarContaData.nome}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 nome: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle"
    //                         placeholder={"Nome"}
    //                     />
    //                     <InputText
    //                         value={editarContaData.razao_social}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 razao_social: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle"
    //                         placeholder={"Razão Social"}
    //                     />
    //                     <InputText
    //                         value={editarContaData.nome_fantasia}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 nome_fantasia: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle"
    //                         placeholder={"Nome Fantasia"}
    //                     />

    //                 <div className="oneLineInputs" >                        
    //                 <CustomInputMask
    //                     value={editarContaData.cnpj}
    //                     onChange={(e) => {
    //                         setEditarContaData( prev => ({
    //                             ...prev,
    //                             cnpj: e.target.value
    //                         }))
    //                     }}
    //                     className="inputStyle margin-right"
    //                     placeholder={"CNPJ"}
    //                     mask={"99.999.999/9999-99"}
    //                 />

    //                 <CustomCurrencyInput
    //                     intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
    //                     prefix='R$'
    //                     allowNegativeValue={false}
    //                     decimalSeparator=","
    //                     groupSeparator="."
    //                     decimalScale={2}
    //                     maxLength={10}
    //                     value={editarContaData.vendas_mensais}
    //                     onValueChange={(value) => 
    //                         setEditarContaData(
    //                             // @ts-ignore
    //                             prev => ({...prev, vendas_mensais: value })
    //                         )
    //                         }
    //                     className="inputStyle"
    //                     placeholder="Vendas Mensais"
    //                 />
    //                 </div>

    //                 <div className="oneLineInputs" >
    //                     <InputText
    //                         value={editarContaData.setor}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 setor: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle margin-right"
    //                         placeholder={"Setor"}
    //                     />
    //                     <InputText
    //                         value={editarContaData.segmento}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 segmento: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle"
    //                         placeholder={"Segmento"}
    //                     />
    //                 </div>

    //                 <h3>Dados de contato</h3>
    //                     <InputText
    //                         value={editarContaData.email}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 email: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle"
    //                         placeholder={"Email"}
    //                     />
    //                 <div className="oneLineInputs" >
    //                     <CustomInputMask
    //                         value={editarContaData.celular}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 celular: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle margin-right"
    //                         placeholder={"Celular"}
    //                         mask={"(99) 99999-9999"}
    //                     />
    //                     <InputText
    //                         value={editarContaData.site}
    //                         onChange={(e) => {
    //                             setEditarContaData( prev => ({
    //                                 ...prev,
    //                                 site: e.target.value
    //                             }))
    //                         }}
    //                         className="inputStyle"
    //                         placeholder={"Site"}
    //                     />
    //                 </div>

    //                 <OutlinedButton
    //                     style={{alignSelf: 'center'}}
    //                     show={showEditarCadastroModal}
    //                     onClick={e => {
    //                         e.preventDefault();
    //                         setShowEditarCadastroModal(false);
    //                         handleEditarConta(editarContaData.id, {...editarContaData});
    //                     }}
    //                 >
    //                     Atualizar Conta
    //                 </OutlinedButton>
    //             </form>
    //         </FullScreenModal>
    //     )
    // }

    const DeletarCadastroModal = () => {
        return(
            <FullScreenModal show={showDeletarCadastroModal} setShow={setShowDeletarCadastroModal} >
                <h2>Deletar uma conta é irreversível. Você deseja deletar esta conta?</h2>
                <form>
                    <h4>Email</h4>
                    <InputText
                        value={showEditarModal.cadastro.email}
                        className="inputStyle"
                        placeholder={"Email"}
                        disabled={true}
                    />

                    <h4>Criado em</h4>
                    <div className="oneLineInputs">
                        <InputText
                            value={parseData(showEditarModal.cadastro.created_at)}
                            className="inputStyle"
                            placeholder={"00/00/0000"}
                            disabled={true}
                        />
                        <div style={{width: '100%'}} />
                    </div>

                    <h4>ID</h4>
                    <div className="oneLineInputs">
                        <InputText
                            value={showEditarModal.cadastro.id}
                            className="inputStyle"
                            placeholder={"Email"}
                            disabled={true}
                        />
                        <div style={{width: '100%'}} />
                    </div>

                    <OutlinedButton
                        style={{alignSelf: 'center'}}
                        show={showDeletarCadastroModal}
                        onClick={e => {
                            e.preventDefault();
                            setShowDeletarCadastroModal(false);
                            handleDeletarConta(showEditarModal.cadastro.id);    
                        }}
                    >
                        Deletar
                    </OutlinedButton>
                </form>
            </FullScreenModal>
        )
    }

    useEffect(() => (
        (() => {
            if(contaId !== null){
                navigate("/dashboard");
            }
        })()
    ),[])

    useEffect(() => {
        loadData();
    },[])
    
    useEffect(() => {
        mudarPaginaData();
    },[pagina.current])

    if(contaId !== null){
        return <></>
    }

    return (
        <Container>
            <header className="headerTabela">
                <h1 className="nomePagina">ADMINISTRATIVO</h1>                
            </header>
            <main>
                <div className="tabelaContainer">
                    <div className="pesquisar">
                        <OutlinedButton 
                            show={true}
                            onClick={() => setShowBuscarCadastroModal(true)}
                        >
                            BUSCAR CONTA
                        </OutlinedButton>
                        <OutlinedButton 
                            show={true}
                            onClick={() => setShowCriarCadastroModal(true)}
                        >
                            CRIAR CONTA
                        </OutlinedButton>
                        
                    </div>

                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <td className="primeiro">EMAIL</td>
                                <td>ID</td>
                                <td>CRIADO EM</td>
                                <td className="ultimo"></td>
                            </tr>
                        </thead>

                        <tbody>
                                {data?.map((obj,idx) => (
                                <tr key={idx}>
                                    <td className="primeiro">{obj.email}</td>
                                    <td>{obj.id}</td>
                                    <td style={{width: '20%'}} >{parseData(obj.created_at)}</td>
                                    <td className="ultimo">                                        
                                        <FiDelete                                            
                                            size={26}
                                            color="#F15A22"
                                            fill='transparent'
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                setShowEditarModal({show: true, cadastro: obj});
                                                setShowDeletarCadastroModal(true);
                                            }}
                                        />                                            
                                    </td>
                                </tr>
                                ))}
                        </tbody>
                    </table>

                    <PageNavigation pagina={pagina} setPagina={setPagina} />
                    
                </div>
            </main>

            {/* //////BuscarCadastroModal////// */}
            <FullScreenModal show={showBuscarCadastroModal} setShow={setShowBuscarCadastroModal} >
                <form>
                <div className="limparFiltroButtonContainer" >
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setFiltroCadastro({
                                        email: '',
                                        id: ''
                                    });
                                }}
                            >
                                Limpar filtros
                            </button>
                        </div>
                    <h3>Filtrar por email</h3>
                        <InputText
                            value={filtroCadastro.email}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    email: e.target.value
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"Email"}
                        />
                    <h3>Filtrar por ID</h3>
                        <InputText
                            value={filtroCadastro.id}
                            onChange={(e) => {
                                setFiltroCadastro( prev => ({
                                    ...prev,
                                    id: e.target.value
                                }))
                            }}
                            className="inputStyle"
                            placeholder={"ID"}
                        />

                    <OutlinedButton
                        style={{alignSelf: 'center'}}
                        show={showBuscarCadastroModal}
                        type='submit'
                        onClick={e => {
                            e.preventDefault();
                            setShowBuscarCadastroModal(false);
                            if(pagina.current !== 1){
                                setPagina(prev => ({...prev, current: 1}));
                            } else {
                                mudarPaginaData();
                            }
                        }}
                    >
                        Buscar
                    </OutlinedButton>
                </form>
            </FullScreenModal>

            <CriarCadastroModal />
            {/* <EditarCadastroModal /> */}
            <DeletarCadastroModal />
            <Loading isLoading={loading} />
        </Container>
    )
}