
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useAuth from "../../../hooks/useAuth";
import { IState } from "../../../store";
import { MeProps } from "../../../store/types";
import { postMeAction } from "../../../actions";
import { getApiTokens, getContaVendedor, putEditarContaVendedor } from "../../../services/service";
import { Container } from "./styles";

import { CustomCurrencyInput } from "../../../components/form/CustomCurrencyInput";
import { CustomInputMask } from "../../../components/form/CustomInputMask";
import { InputPassword } from "../../../components/form/InputPassword";
import { InputText } from "../../../components/form/InputText";
import { SubmitButton } from "../../../components/form/SubmitButton";
import { Loading } from "../../../components/Loading/Loading";
import { OutlinedButton } from "../../../components/OutlinedButton";

interface DadosUsuarioProps {
    id: string;
    nome: string;
    celular: string;
    email: string;
    cnpj: string;
    nome_fantasia: string;
    site: string;
    segmento: string;
    setor: string;
    razao_social: string;
    vendas_mensais: string;
    // senha: string;
}

// nome;
// celular;
// email;
// cnpj;
// nome_fantasia;
// site;
// segmento;
// setor;
// razao_social;
// vendas_mensais;
// senha;

const parseParceiroGetToPut = (id: string, obj: DadosUsuarioProps) => {
    return(
        {
            id: id,
            email: obj.email,
            conta: {
                razao_social: obj.razao_social,
                cnpj: obj.cnpj,
                nome : obj.nome,
                celular : obj.celular ,
                nome_fantasia: obj.nome_fantasia,
                site: obj.site,
                segmento: obj.segmento,
                setor: obj.setor,
                vendas_mensais: obj.vendas_mensais,
            }
        }
    )
}

export function Conta() {
    const {token, contaId} = useAuth();
    const dispatch = useDispatch();
    const me = useSelector<IState, MeProps>((state) => state.me);
    const [dadosUsuario, setDadosUsuario] = useState<DadosUsuarioProps>({} as DadosUsuarioProps);
    const [showTokens, setShowTokens] = useState(false);
    const [tokenKeys, setTokenKeys] = useState({client_id: "", client_secret: ""});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (me.conta_id) {
                await loadData();
            }
        })()
    }, [me])

    useEffect(() => {
        (async () => {
            await dispatch(postMeAction(token) as any);
        })()

    }, [])

    async function loadData() {
        setLoading(true);
        try {
            const res = await getContaVendedor(token, me.conta_id!);            
            setDadosUsuario(res.data)
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    async function handleAlteralDados() {
        setLoading(true);
        try {
            await putEditarContaVendedor(token, parseParceiroGetToPut(me.conta_id!, dadosUsuario));
            toast.success("Seus dados foram alterados com sucesso!")
        } catch (err) {
            toast.error("Erro ao editar os dados, verifique os dados e tente novamente.")
            console.log(err);
        }
        setLoading(false);
    }

    const handleShowTokens = async () => {
        if(showTokens){
            setShowTokens(false);
        } 
        else{
            try {
                if(tokenKeys.client_id === ''){
                    setLoading(true);
                    const res = await getApiTokens(token);
                    setTokenKeys(res.data);
                }                
                setShowTokens(true);
            } catch (err) {
                console.log(err);
                toast.error('Ocorreu um erro ao solicitar os tokens. Tente novamente mais tarde')           
            } finally {
                setLoading(false);
            }
        }
    }

    const DadosCadastrais = () => {
        return(
            <form>
                        <div className="inputsContainer">
                            <div className="inputWrapper w50">
                                <label className="inputLabel">CONTA ID</label>
                                <InputText
                                    value={dadosUsuario.id}
                                    className="inputStyle"
                                    placeholder={"CONTA ID"}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="inputsContainer">
                            <div className="inputWrapper w33">
                                <label className="inputLabel">NOME COMPLETO</label>
                                <InputText
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            nome: e.target.value
                                        })
                                    }}
                                    value={dadosUsuario.nome}
                                    className="inputStyle"
                                    placeholder={"João da Silva"}
                                />
                            </div>
                            <div className="inputWrapper w33">
                                <label className="inputLabel">CELULAR</label>
                                <CustomInputMask
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            celular: e.target.value
                                        })
                                    }}
                                    value={dadosUsuario.celular}
                                    mask={"(99) 9 9999-9999"}
                                    placeholder={"(99) 9 9999-9999"}
                                    className="inputStyle"
                                />
                            </div>
                            <div className="inputWrapper w33">
                                <label className="inputLabel">E-MAIL</label>
                                <InputText
                                    value={dadosUsuario.email}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            email: e.target.value
                                        })
                                    }}
                                    className="inputStyle"
                                    placeholder={"joaoSilva@email.com"}
                                />
                            </div>
                        </div>
                        <div className="inputsContainer">
                        <div className="inputWrapper w33">
                                <label className="inputLabel">RAZAO SOCIAL</label>
                                <InputText
                                    value={dadosUsuario.razao_social}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            razao_social: e.target.value
                                        })
                                    }}
                                    className="inputStyle"
                                    placeholder={"Minha empresa"}
                                />
                            </div>
                            <div className="inputWrapper w33">
                                <label className="inputLabel">NOME FANTASIA</label>
                                <InputText
                                    value={dadosUsuario.nome_fantasia}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            nome_fantasia: e.target.value
                                        })
                                    }}
                                    className="inputStyle"
                                    placeholder={"Minha empresa"}
                                />
                            </div>
                            <div className="inputWrapper w33">
                                <label className="inputLabel">CNPJ</label>
                                <CustomInputMask
                                    value={dadosUsuario.cnpj}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            cnpj: e.target.value
                                        })
                                    }}
                                    mask={"99.999.999/9999-99"}
                                    placeholder={"99.999.999/9999-99"}
                                    className="inputStyle"
                                />
                            </div>
                        </div>
                        <div className="inputsContainer">
                            <div className="inputWrapper w20">
                                <label className="inputLabel">SITE</label>
                                <InputText
                                    value={dadosUsuario.site}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            site: e.target.value
                                        })
                                    }}
                                    className="inputStyle"
                                    placeholder={"http://meusite.com.br"}
                                />
                            </div>
                            <div className="inputWrapper w20">
                                <label className="inputLabel">SEGMENTO</label>
                                <InputText
                                    value={dadosUsuario.segmento}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            segmento: e.target.value
                                        })
                                    }}
                                    className="inputStyle"
                                    placeholder={""}
                                />
                            </div>
                            <div className="inputWrapper w20">
                                <label className="inputLabel">SETOR</label>
                                <InputText
                                    className="inputStyle"
                                    placeholder={""}
                                    value={dadosUsuario.setor}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            setor: e.target.value
                                        })
                                    }}
                                />
                            </div>                        
                            <div className="inputWrapper w20">
                                <label className="inputLabel">VENDAS MENSAIS</label>
                                <InputText
                                    value={dadosUsuario.vendas_mensais}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            vendas_mensais: e.target.value
                                        })
                                    }}
                                    className="inputStyle"
                                    placeholder={"Minha empresa"}
                                />
                                {/* <CustomCurrencyInput
                                    value={dadosUsuario.cnpj}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            cnpj: e.target.value
                                        })
                                    }} 
                                    className="inputStyle"
                                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                    placeholder="R$ 1.000,00"
                                    prefix='R$'
                                    allowNegativeValue={false}
                                    decimalSeparator=","
                                    groupSeparator="."
                                    decimalScale={2}
                                    maxLength={10}
                                /> */}
                            </div>
                            {/* <div className="inputWrapper w33">
                                <label className="inputLabel">SENHA</label>
                                <InputPassword
                                    value={dadosUsuario.senha}
                                    onChange={(e) => {
                                        setDadosUsuario({
                                            ...dadosUsuario,
                                            senha: e.target.value
                                        })
                                    }}
                                    className="inputStyle" />
                            </div> */}
                        </div>
                        <div className="btnContainer">
                            <SubmitButton type="button" onClick={handleAlteralDados} color="laranja" text="SALVAR" />
                        </div>
                    </form>
        )
    }

    const Tokens = () => {
        const handleCopiarToken = (token: 'client_id' | 'client_secret') => {
            try {
                if( navigator.clipboard !== undefined){
                  navigator.clipboard.writeText(`${tokenKeys[token]}`);
                  toast.success(`A chave ${token.replace('_', ' ')} foi copiada.`);

                } else {
                  const textArea = document.createElement("textarea");
                  textArea.value = `${tokenKeys[token]}`;
                  document.body.appendChild(textArea);
                  textArea.focus();
                  textArea.select();
          
                  try {
                      document.execCommand('copy');
                      toast.success(`A chave ${token.replace('_', ' ')} foi copiada.`);

                  } catch (err) {
                    console.log(err);   
                  }
                  finally{
                      document.body.removeChild(textArea);
                  }
                }
              } catch (err) {
                console.log(err);
                toast.error(`Ocorreu um erro ao copiar automaticamente a chave ${token.replace('_', ' ')}.`);
              }
        }

        return (
            <form>
                <div className="inputWrapper w50">
                    <label className="inputLabel">CLIENT ID</label>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <InputText
                            value={tokenKeys.client_id}
                            className="inputStyle"
                            readOnly
                            />
                        <span
                            style={{marginLeft: '10px', cursor: 'pointer'}}
                            onClick={() => handleCopiarToken('client_id')}
                        >copiar</span>
                    </div>
                </div>
                <div className="inputWrapper w50">
                    <label className="inputLabel">CLIENT SECRET</label>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <InputPassword
                            value={tokenKeys.client_secret}
                            className="inputStyle"
                            readOnly
                        />
                        <span
                            style={{marginLeft: '10px', cursor: 'pointer'}}
                            onClick={() => handleCopiarToken('client_secret')}
                        >copiar</span>
                    </div>
                </div>
            </form>
        )
    }

    return (
        <Container>
            <Loading isLoading={loading} />
            <header className="headerTabela">
                <h1 className="nomePagina">MINHA CONTA</h1>
            </header>
            <main>
                <div className="container">
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h2 className="nomeSessao">INFORMAÇÕES DA CONTA</h2>
                        {contaId === null
                        ?
                            <div />
                        :
                            <OutlinedButton
                                show={true}
                                onClick={handleShowTokens}
                            >
                                {showTokens ? 'Voltar' : 'Tokens'}
                            </OutlinedButton>
                        }
                    </div>
                    {showTokens
                    ?
                        <Tokens />
                    :
                        <DadosCadastrais />
                    }
                </div>
            </main>

        </Container >
    )
}

