import styled, { keyframes } from "styled-components";

interface showProps {
    show: boolean,
}

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`;

export const Container = styled.section`
    
    .headerTabela{
    
    padding: 36px 64px 0px 64px;

        .nomePagina{
            font-size: 2rem;
        }

    }

    main{
        padding:  24px 64px;
        margin: 0 auto;

        .pesquisar{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
        }

        .tabelaContainer{
            width: 100%;
            background-color: var(--background);
            padding: 30px;
            border-radius: 30px;
            overflow: auto;

            table{
                width: 100%;
                border: none;
                margin-top: 30px;
                thead{
                    border: none;
                    tr{
                        border: none;
                        td{
                            border: none;
                            font-size: 1rem;
                            font-weight: bold;
                            padding: 0px 7px 26px;
                            color: var(--thead-color);
                        }

                    }
                }
                tbody{
                    border: none;
                    tr{
                        border: none;
                        td{
                            border: none;
                            padding: 0px 7px;
                            color: var(--tbody-color);
                        }
                        .primeiro{

                        }

                        .ultimo{                            
                            cursor: pointer;
                        }

                        &:hover{
                            transition: 0.2s;
                            background-color: var(--table-hover);
                        }
                    }
                }

                .centerColumn{
                    text-align: center;
                }

                .status-true{
                    padding: 5px 10px;
                    border-radius: 32px;
                    background-color: #C9ECE7;
                    color: #00B57D;
                }

                .status-false{
                    padding: 5px 10px;
                    border-radius: 32px;
                    background-color: #C9DBF2;
                    color: #75B1ED;
                }

            }

            .paginationContainer{
                display: flex;
                justify-content: center;
                align-items: center;

                .pageNumber{
                    border: none;
                    background-color: transparent;
                    font-size: 1.25rem;
                }

            }
        }

        @media only screen and (max-width: 900px) {
            padding:  24px 0 0 0;
        }
    }

    .buttonInput {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        padding:10px;
        padding-left: 20px;
        outline: none;
        background-color:var(--input-background);
        color: var(--roxo-700);
        font-size: 1rem;
        border-radius: 18px;
        border: 1px solid var(--roxo-700);
        font-weight: normal;
        border: none;
        color: var(--input-text-color);

        @media (max-width: 1300px) {
            height: 0.8rem;
            padding: 1.33rem;
        }
    }
`

export const MiniModal = styled.div<showProps>`
    width: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;

    div{
        position: absolute;
        bottom: -100%;
        right: 0;
        /* width: 100%; */
        /* height: 360px; */
        padding: 10px 30px;
        outline: none;
        background-color: #F0EFF9;
        color: var(--roxo-700);
        font-size: 1rem;
        border-radius: 18px;
        border: 1px solid var(--roxo-700);
        font-weight: normal;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        animation: ${props => props.show ? fadeIn : fadeOut} 0.2s linear;
        transition: visibility 0.2s linear;
        visibility: ${props => props.show ? 'visible' : 'hidden' };

        .optionsButton{
            border: none;
            background-color: transparent;

            margin: 8px 0px;
        }
    }

`

export const CardsContainer = styled.div`
    padding:  24px 64px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-row-gap: 30px;
    grid-column-gap: 30px;

    @media only screen and (max-width: 900px) {
        padding: 10px 30px 0;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
    }

    .card{
        display: flex;
        flex-direction: column;
        background: linear-gradient(290deg ,var(--amarelo-300) 0%,  var(--laranja-500) 75%); 
        min-width: 100px;
        min-height: 10rem;
        border-radius: 27px;
        justify-content: center;
        align-items: center;

        p {
            color: var(--white);

            &.title {
                font-size: 2rem;
                font-weight: bold;
            }
            
            &.text {
                font-size: 1rem;
            }
    
        }

        @media only screen and (max-width: 900px) {
            min-height: 60px;
        }
    }
`